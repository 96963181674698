export default {
  general: {
    username: 'Uživatelské jméno',
    password: 'Heslo',
    newpass: 'Nové heslo',
    currentpass: 'Současné heslo',
    c_password: 'Napište ještě jednou současné heslo',
    c_newpass: 'Napište ještě jednou nové heslo',
    email: 'Emailová addresa',
    language: 'Jazyk',
    subject: 'Předmět',
    message: 'Zpráva',
    filtername: 'Název filtru',
    placeholders: {
      username: 'Napište uživatelské jméno',
      password: 'Napište heslo',
      newpass: 'Napište nové heslo',
      currentpass: 'Napište současné heslo',
      c_password: 'Potvrďte heslo',
      c_newpass: 'Potvrďte nové heslo',
      email: 'Napište email',
      subject: 'Napište předemět',
      message: 'Napište zprávu',
      filtername: 'Napište název filtru'
    },
    errors: {
      language: {
        load: 'Nepodařilo se změnit jazyk.'
      }
    },
    here: 'zde',
    ok: 'Ok',
    cancel: 'Zavřít',
    on: 'ano',
    off: 'ne',
    min: 'Minimální cena',
    max: 'Maximální cena',
    years: '{n} let | {n} rok | {n} roky | {n} roky | {n} roky | {n} let',
    days: '{n} den | {n} dní',
    type_for_more: 'Pište, abyste viděli více...'
  },
  notifications: {
    disabled: '{notifications} jsou zakázány. Pokud jste nezakázali, zkuste použít jiný prohlížeč.',
    notifications: 'Notifikace',
    title: 'Nastavení upozornění',
    form: {
      email: 'Emailová upozornění',
      email_frequency: 'Frekvence upozornění přes email',
      browser: 'Upozornění v prohlížeči',
      browser_frequency: 'Frekvence upozornění v prohlížeči',
      frequency: 'okamžitě | jednou za {n} hodinu | jednou za {n} hodiny | jednou za {n} hodin ',
      help: {
        email: 'Upozornění na hráče obdržíte na email.',
        browser: 'Upozornění na hráče obdržíte přímo do prohlížeče.',
        frequency: 'Doporučené nastavení je získávat upozornění \'okamžitě\', abyste nepřišli o žádného hráče.',
        blocked: 'Upozornění jste buď zakázali, nebo je váš prohlížeč nepodporuje.'
      }
    }
  },
  header: {
    menu: {
      home: 'Domů',
      account: 'Účet',
      signin: 'Přihlásit se',
      register: 'Registrovat se',
      inbox: 'Inbox',
      blog: 'Blog',
      contact: 'Napište nám',
      logout: 'Odhlásit se'
    }
  },
  footer: {
    menu: {
      privacy: 'Soukromí (anglický)',
      terms: 'Podmínky (anglický)'
    }
  },
  login: {
    title: 'Přihlásit se',
    signin: 'Přihlásit se',
    register: 'Zaregistrovat se',
    forgot: 'Zapomněli jste heslo?',
    form: {
      submit: 'Přihlásit se',
      remember: 'Zapamatuj mé přihlášení'
    },
    info: {
      what: 'K čemu je dobrý Mercattrick?',
      purpose: 'Účel Mercattricku je:',
      purpose1: 'pomoct <strong>manažerům</strong> Hattricku získat větší přehled o hráčích, které <strong>prodávají</strong>, a díky tomu získat nové možnosti;',
      purpose2: 'posílat <strong>manažerům</strong> notifikace, když se objeví na trhu hráč se specifickými vlastnostmi, které hledají;',
      how: 'Jak to funguje?',
      buyers: 'Ti, co chtějí koupit hráče',
      step1: 'se přihlásí na Mercattrick a nastaví požadavky na hráče, o kterých by chtěli získávat upozornění, pokud by se vyskytli na trhu.',
      sellers: 'Ti, co chtějí prodat hráče',
      step2: 'tak dají na HT hráče na prodej a poté mohou zde na Mercattricku "propagovat" prodej svého hráče.',
      promoting: 'Propagovat',
      step3: 'hráče znamená, že ti, kteří takového hráče hledají, obdrží upozornění na jeho prodej.'
    }
  },
  register: {
    title: 'Zaregistrovat se',
    form: {
      registered: 'Váš účet byl založen. Klikněte {here} pro dokončení registrace.',
      submit: 'Registrovat se',
      agreement: 'Kliknutím na @:register.form.submit souhlasíte s {terms} a {privacy} a případně zasíláním emailů souvisejících s chodem programu (například notifikace o hráčích na prodej).',
      tos: 'podmínky používání',
      privacy: 'prohlášení o soukromí',
      help: {
        username: 'Toto není vaše uživatelské jméno na Hattricku.',
        password: 'Heslo musí mít minimálně 6 znaků. Rozlišujte velká a malá písmena.',
        email: 'Na tuto adresu budeme zasílat upozornění na nové hráče na přestupové listině'
      }
    }
  },
  recover: {
    title: 'Obnovit heslo',
    form: {
      recovered: 'Email s odkazem na obnovení hesla byl poslán na vaši emailovou adresu',
      submit: 'Obnovit heslo',
      help: {
        email: 'Emailová adresa musí být provázána s vaším účtem na Mercattricku.'
      }
    }
  },
  reset: {
    title: 'Resetovat heslo',
    form: {
      submit: 'Resetovat heslo',
      help: {
        email: 'Emailová adresa musí být provázána s vaším účtem na Mercattricku.',
        password: 'Heslo musí mít minimálně 6 znaků. Rozlišujte velká a malá písmena.'
      }
    }
  },
  contact: {
    title: 'Napište nám',
    form: {
      success: 'Zpráva byla úspěšně odeslána.',
      submit: 'Odeslat',
      help: {
        email: 'Vaši emailovou adresu použijeme pouze k tomu, abychom vám mohli odpovědět.'
      }
    }
  },
  sync: {
    users: {
      syncing: 'Synchronizace údajů o uživateli...',
      fail: 'Synchronizace uživatele se nezdařila.',
      retry: 'Zkusit znovu',
      authorize: 'Tento CHPP produkt musí být autorizován. Klikněte {here} , abyste otevřeli web Hattriku.',
      authorizing: 'Autorizace se inicializuje. Za chvíli budete přesměrováni na <strong>hattrick.org</strong>...',
      finalize: 'Dokončování autorizace...'
    },
    system: {
      syncing: 'Synchronizace systémových detailů...',
      fail: 'Systémová synchronizace byla neúspěšná. {retry} nebo to můžete {skip}!',
      retry: 'Zkuste to znovu',
      skip: 'přeskočit'
    }
  },
  account: {
    account: {
      title: 'Nastavení účtu',
      form: {
        success: 'Účet byl úspěšně aktualitzován.',
        submit: 'Uložit',
        help: {
          username: 'Toto není vaše uživatelské jméno na Hattricku.',
          email: 'Tuto emailovou adresu použijeme, aby jsme vás mohli informovat o nových hráčích.'
        }
      }
    },
    login: {
      title: 'Nastavení přihlašování',
      form: {
        success: 'Heslo bylo úspěšně aktualizováno.',
        submit: 'Uložit',
        help: {
          passwords: 'Napište své stávající heslo, nové heslo a poté ještě jednou nové heslo pro potvrzení.'
        }
      }
    },
    privacy: {
      title: 'Nastavení soukromí',
      form: {
        submit: 'Smazat účet',
        delete: 'Smazat účet',
        help: {
          warning: 'Smazaná data nelze obnovit.',
          confirmation: 'Prosím potvrďte, že opravdu chcete trvale odstranit veškerá svá data.'
        }
      }
    }
  },
  filter: {
    title: 'Klikněte pro přidání/odebrání filtru',
    form: {
      submit: 'Uložit',
      set: 'Nastavit',
      remove: 'Odstranit',
      help: {
        name: 'Pojmenujte svůj filtr, abyste ho mohli později snáze najít.'
      }
    },
    tooltip: {
      save: 'Musíte nastavit alespoň jedno kritérium pro filtraci hráčů!'
    },
    item: {
      tooltip: 'Stiskněte \'@:filter.item.show_players\', abyste zobrazili seznam.',
      show_players: 'Zobrazit hráče',
      hide_players: 'Skrýt hráče',
      players: 'hráč nalezen | hráči nalezeni',
      form: {
        processing: 'zpracovávám',
        fetch: 'Načíst hráče HT',
        edit: 'Upravit',
        delete: 'Odstranit',
        help: {
          confirm: 'Opravdu chcete odstranit tento filtr?'
        }
      }
    }
  },
  home: {
    myplayers: {
      title: 'Moje přestupy',
      loading: 'Načítání hráčů...',
      none: 'Momentálně nemáte žádné hráče na prodej.',
      promoted: 'propagován',
      late: 'příliš pozdě pro propagaci',
      form: {
        reload: 'Načíst znovu',
        promote: 'Propagovat'
      },
      tooltip: 'Propagujte své hráče, aby je ostatní mohli vidět.'
    },
    bookmarks: {
      title: 'Záložky',
      loading: 'Načítání hráčů...',
      none: 'Momentálně nemáte v záložkách žádného hráče.',
      form: {
        reload: 'Načíst znova',
        unbookmark: 'Odebrat ze záložek',
        help: {
          confirm: 'Opravdu chcete tohoto hráče odebrat ze záložek?'
        }
      },
      tooltip: 'Na tyto hráče obdržíte upozornění, pokud se bude blížit jejich uzávěrka na přestupové listině.'
    },
    filters: {
      title: 'Moje filtry',
      loading: 'Načítání filtrů...',
      none: 'Nemáte žádné filtry Musíte nejprve nějaké vytvořit.',
      form: {
        reload: 'Načíst znova',
        add: 'Přidat filtr',
        bookmark: 'Přidat do záložek',
        hide: 'Skrýt',
        help: {
          confirm: 'Opravdu chcete skrýt tohoto hráče?'
        }
      },
      tooltip: 'Zajímavé hráče ze seznamů můžete přidat do záložek.'
    }
  },

  player: {
    attributes: {
      name: 'Jméno',
      age: 'Věk',
      form: 'Forma',
      experience: 'Zkušenosti',
      leadership: 'Vůdcovství',
      stamina_skill: 'Kondice',
      keeper_skill: 'Chytání',
      playmaker_skill: 'Tvorba hry',
      scorer_skill: 'Zakončování',
      passing_skill: 'Přihrávky',
      winger_skill: 'Křídlo',
      defender_skill: 'Bránění',
      set_pieces_skill: 'Standardky',
      agreeability: 'Příjemnost',
      aggressiveness: 'Temperament',
      honesty: 'Čestnost',
      htms: 'HTMS',
      htms28: 'HTMS28',
      tsi: 'TSI',
      salary: 'Plat',
      price: 'Cena',
      asking_price: 'Požadovaná částka',
      highest_bid: 'Nejvyšší nabídka',
      native_country: 'Země',
      native_country_id: 'Země',
      NT_player: 'Hráč národního týmu',
      specialties: 'Speciality',
      specialty: 'Specialita'
    },
    redcard: 'červená karta',
    yellowcard: 'žlutá karta',
    bruised: 'poraněn na náplast',
    injured: 'zraněn',
    injury: '{n} týdnů | {n} týden | {n} týdny | {n} týdny | {n} týdny | {n} týdnů',
    fullage: '{years} let a {days} dní',
    deadline: 'Uzávěrka',
    htms: 'HTMS/HTMS28',
    more: 'Ukázat více',
    less: 'Ukázat méně',
    levels: {
      skill: [
        'neexistující',
        'odstrašující',
        'katastrofální',
        'tristní',
        'nedostačující',
        'neadekvátní',
        'ucházející',
        'solidní',
        'excelentní',
        'nadstandardní',
        'vynikající',
        'brilantní',
        'perfektní',
        'prvotřídní',
        'abnormální',
        'kolosální',
        'impozantní',
        'dechberoucí',
        'okouzlující',
        'legendární',
        'Boží',
        'Boží (+1)',
        'Boží (+2)',
        'Boží (+3)',
        'Boží (+4)',
        'Boží (+5)'
      ],
      agreeability: [
        'Protiva',
        'Hašteřivý člověk',
        'Příjemný kluk',
        'Sympatický chlapík',
        'Oblíbený hráč',
        'Miláček týmu'
      ],
      aggressiveness: [
        'ledově klidný',
        'chladnokrevný',
        'vyrovnaný',
        'temperamentní',
        'prchlivý',
        'labilní'
      ],
      honesty: [
        'bezectný',
        'nečestný',
        'čestný',
        'férový',
        'spravedlivý',
        'učiněný andílek'
      ],
      specialty: [
        'žádná',
        'Technický',
        'Rychlý',
        'Silový',
        'Nepředvídatelný',
        'Hlavičkář',
        'Houževnatý',
        '',
        'Týmový hráč'
      ],
      national_team: [
        'nikdy',
        'momentálně ne',
        'momentálně ano'
      ],
      any: 'jakýkoliv/žádný'
    },
    tooltips: {
      highest_bid: 'Nejvyšší nabídka může být zastaralá!'
    }
  },
  countries: {
    1: 'Švédsko',
    2: 'Anglie',
    3: 'Německo',
    4: 'Itálie',
    5: 'Francie',
    6: 'Mexiko',
    7: 'Argentina',
    8: 'USA',
    9: 'Norsko',
    10: 'Dánsko',
    11: 'Finsko',
    12: 'Nizozemsko',
    13: 'Oceánie',
    14: 'Kanada',
    15: 'Skotsko',
    16: 'Irsko',
    17: 'Chile',
    18: 'Kolumbie',
    19: 'Uruguay',
    20: 'Venezuela',
    21: 'Peru',
    22: 'Brazílie',
    23: 'Portugalsko',
    24: 'Jižní Afrika',
    25: 'Japonsko',
    26: 'Polsko',
    27: 'Indie',
    28: 'Čína',
    29: 'Jižní Korea',
    30: 'Thajsko',
    31: 'Turecko',
    32: 'Egypt',
    33: 'Rakousko',
    34: 'Rusko',
    35: 'Španělsko',
    36: 'Rumunsko',
    37: 'Island',
    38: 'Belgie',
    39: 'Malajsie',
    40: 'Švýcarsko',
    41: 'Singapur',
    42: 'Chorvatsko',
    43: 'Srbsko',
    44: 'Maďarsko',
    45: 'Řecko',
    46: 'Česká republika',
    47: 'Estonsko',
    48: 'Lotyšsko',
    49: 'Indonésie',
    50: 'Filipíny',
    51: 'Izrael',
    52: 'Taiwan',
    53: 'Hong Kong',
    55: 'Bulharsko',
    56: 'Wales',
    57: 'Slovinsko',
    61: 'Litva',
    62: 'Ukrajina',
    63: 'Bosna a Hercegovina',
    64: 'Pákistán',
    65: 'Vietnam',
    66: 'Slovensko',
    67: 'Paraguay',
    68: 'Ekvádor',
    69: 'Bolívie',
    70: 'Nigérie',
    71: 'Faerské ostrovy',
    72: 'Maroko',
    75: 'Saúdská Arábie',
    76: 'Tunisko',
    77: 'Kostarika',
    78: 'Spojené arabské emiráty',
    79: 'Lucembursko',
    80: 'Írán',
    82: 'Kypr',
    83: 'Dominikánská republika',
    86: 'Senegal',
    87: 'Bělorusko',
    88: 'Severní Irsko',
    89: 'Jamajka',
    90: 'Keňa',
    91: 'Panama',
    92: 'Makedonie',
    93: 'Kuba',
    94: 'Albánie',
    95: 'Honduras',
    96: 'Salvador',
    97: 'Malta',
    98: 'Kyrgyzstán',
    99: 'Moldavsko',
    100: 'Gruzie',
    101: 'Andorra',
    102: 'Guatemala',
    103: 'Jordánsko',
    104: 'Arménie',
    105: 'Trinidad & Tobago',
    121: 'Nikaragua',
    122: 'Kazachstán',
    123: 'Surinam',
    125: 'Lichtenštejnsko',
    126: 'Alžírsko',
    127: 'Mongolsko',
    128: 'Libanon',
    129: 'Bahrajn',
    130: 'Barbados',
    131: 'Kapverdy',
    132: 'Pobřeží slonoviny',
    133: 'Ázerbájdžán',
    134: 'Kuvajt',
    135: 'Irák',
    136: 'Černá Hora',
    137: 'Angola',
    138: 'Bangladéš',
    139: 'Jemen',
    140: 'Omán',
    142: 'Mosambik',
    143: 'Brunej',
    144: 'Ghana',
    145: 'Kambodža',
    147: 'Benin',
    148: 'Sýrie',
    149: 'Katar',
    150: 'Tanzánie',
    153: 'Uganda',
    154: 'Maledivy',
    163: 'Uzbekistán',
    165: 'Kamerun',
    166: 'Palestina',
    175: 'Srí Lanka',
    177: 'Svatý Tomáš a Princův ostrov',
    178: 'Curaçao',
    179: 'Guam',
    180: 'Komory',
    181: 'DR Kongo',
    182: 'Etiopie',
    183: 'Madagaskar',
    184: 'Svatý Vincenc a Grenadiny',
    185: 'Botswana',
    186: 'Belize',
    187: 'Zambie',
    188: 'Haiti',
    189: 'Myanmar',
    190: 'Portoriko',
    191: 'San Marino',
    192: 'Nepál',
    193: 'Burkina Faso',
    194: 'Grenada',
    196: 'Tahiti',
    197: 'Gujana'
  },

  validation: {
    attributes: {},
    messages: {
      alpha_num: 'Toto pole smí obsahovat pouze alfanumerické znaky.',
      alpha_dash: 'Toto pole smí obsahovat pouze písmena, čísla, podtržítka ( _ ) nebo pomlčky (–) .',
      confirmed: 'Hesla se neshodují.',
      email: 'Toto pole musí obsahovat emailovou adresu.',
      max: 'Toto pole obsahuje příliš moc znaků.',
      min: 'Toto pole obsahuje příliš málo znaků.',
      required: 'Toto pole je povinné.'
    }
  },

  api: {
    validation: {
      string: {
        subject: 'Předmět je povinný',
        body: 'Obsah je povinný.',
        username: 'Uživatelské jméno je povinné.',
        password: 'Heslo je povinné.',
        locale: 'Jazyk je povinný.',
        name: 'Jméno je povinné.',
        token: 'Token je povinný.'
      },
      integer: {
        native_country_id: 'ID země musí být číslo.',
        htms_min: 'Minimální hodnota HTMS musí být číslo',
        htms_max: 'Maximální hodnota HTMS musí být číslo',
        htms28_min: 'Minimální hodnota HTMS28 musí být číslo',
        htms28_max: 'Maximální hodnota HTMS28 musí být číslo',
        tsi_min: 'Minimální hodnota TSI musí být číslo',
        tsi_max: 'Maximální hodnota TSI musí být číslo.'
      },
      numeric: {
        age_min: 'Minimální věk musí být číslo.',
        age_max: 'Maximální věk musí být číslo.',
        salary_min: 'Minimální plat musí být číslo.',
        salary_max: 'Maximální plat musí být číslo.',
        price_min: 'Minimální cena musí být číslo',
        price_max: 'Maximální cena musí být číslo.'
      },
      required: {
        subject: 'Předmět je povinný.',
        body: 'Obsah je povinný.',
        username: 'Uživatelské jméno je povinné.',
        password: 'Heslo je povinné.',
        current_password: 'Musíte zadat současné heslo',
        new_password: 'Musíte zadat nové heslo.',
        email: 'Email je povinný.',
        token: 'Token je povinný.',
        endpoint: ' \'endpoint\' parametr je povinný.',
        url: '\'URL\' parametr je povinný.',
        oauth_token: '\'oauth_token\' parametr je povinný.',
        oauth_verifier: '\'oauth_verifier\' parametr je povinný.'
      },
      max: {
        age_min: 'Minimální věk musí být nižší než 100.',
        age_max: 'Maximální věk musí být nižší než 100.',
        htms_min: 'Minimální hodnota HTMS musí být nižší než 12 000.',
        htms_max: 'Maximální hodnota HTMS musí být nižší než 12 000.',
        htms28_min: 'Minimální hodnota HTMS28 musí být nižší než 12 000.',
        htms28_max: 'Maximální hodnota HTMS28 musí být nižší  než 12 000.',
        tsi_min: 'Minimální hodnota ITC musí být nižší 16 000 000.',
        tsi_max: 'Maximální hodnota ITC musí být nižší 16 000 000.',
        subject: 'Předmět obsahuje příliš moc znaků.',
        username: 'Uživatelské jméno obsahuje příliš moc znaků.',
        email: 'Email obsahuje příliš moc znaků.',
        locale: 'Jazyk obsahuje příliš moc znaků.',
        name: 'Jméno obsahuje příliš moc znaků.',
        token: 'Token obsahuje příliš moc znaků.',
        NT_player: 'Hodnota hráče národního týmu musí být maximálně 2 (\'@:player.levels.national_team[2]\').',

        string: {
          subject: '@:api.validation.max.subject',
          username: '@:api.validation.max.username',
          email: '@:api.validation.max.email',
          locale: '@:api.validation.max.locale',
          name: '@:api.validation.max.name',
          token: '@:api.validation.max.token',
          NT_player: '@:api.validation.max.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.max.age_min',
          age_max: '@:api.validation.max.age_max',
          htms_min: '@:api.validation.max.htms_min',
          htms_max: '@:api.validation.max.htms_max',
          htms28_min: '@:api.validation.max.htms28_min',
          htms28_max: '@:api.validation.max.htms28_max',
          tsi_min: '@:api.validation.max.tsi_min',
          tsi_max: '@:api.validation.max.tsi_max',
          NT_player: '@:api.validation.max.NT_player'
        }
      },
      min: {
        password: 'Heslo obsahuje příliš málo znaků.',
        age_min: 'Minimální věk musí být vyšší nebo roven 17.',
        age_max: 'Maximální věk musí být vyšší nebo roven 17.',
        native_country_id: 'ID země musí být vyšší než 0.',
        htms_min: 'Minimální hodnota HTMS musí být kladné číslo.',
        htms_max: 'Maximální hodnota HTMS musí být kladné číslo.',
        htms28_min: 'Minimální hodnota HTMS28 musí být větší než -12 000.',
        htms28_max: 'Maximální hodnota HTMS28 musí být větší než -12 000.',
        tsi_min: 'Minimální hodnota TSI musí být kladné číslo',
        tsi_max: 'Maximální hodnota TSI musí být kladné číslo',
        salary_min: 'Minimální plat musí být kladné číslo',
        salary_max: 'Maximální plat musí být kladné číslo',
        price_min: 'Minimální cena musí být kladné číslo.',
        price_max: 'Minimální cena musí být kladné číslo.',
        NT_player: 'Cena hráče národního týmu musí být minuímálně 0 (\'@:player.levels.national_team[0]\').',

        string: {
          password: '@:api.validation.min.password',
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        }
      },
      email: {
        email: 'Pole pro email musí obsahovat platnou emailovou adresu.'
      },
      url: {
        url: 'Parametr \'URL\' musí obsahovat platné URL.'
      },
      in: {
        endpoint: '\'endpoint\' parametr musí být platný.',
        form_min: 'Minimální hodnota formy musí být mezi 1 (\'@:player.levels.skill[1]\') a 8 (\'@:player.levels.skill[8]\').',
        form_max: 'Maximální hodnota formy musí být mezi  1 (\'@:player.levels.skill[1]\') a 8 (\'@:player.levels.skill[8]\').',
        agreeability_min: 'Minimální hodnota příjemnosti musí být mezi 0 (\'@:player.levels.agreeability[0]\') a 5 (\'@:player.levels.agreeability[5]\').',
        agreeability_max: 'Maximální hodnota příjemnosti musí být mezi 0 (\'@:player.levels.agreeability[0]\') a 5 (\'@:player.levels.agreeability[5]\').',
        aggressiveness_min: 'Minimální hodnota temperamentu musí být mezi 0 (\'@:player.levels.aggressiveness[0]\') a 5 (\'@:player.levels.aggressiveness[5]\').',
        aggressiveness_max: 'Maximální hodnota temperamentu musí být mezi 0 (\'@:player.levels.aggressiveness[0]\') a 5 (\'@:player.levels.aggressiveness[5]\').',
        honesty_min: 'Minimální hodnota čestnosti musí být mezi 0 (\'@:player.levels.honesty[0]\') a 5 (\'@:player.levels.honesty[5]\').',
        honesty_max: 'Maximální hodnota příjemnosti musí být mezi 0 (\'@:player.levels.honesty[0]\') a 5 (\'@:player.levels.honesty[5]\').',
        experience_min: 'Minimální hodnota zkušenosti musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        experience_max: 'Maximální hodnota zkušenosti musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        leadership_min: 'Minimální hodnota vůdcovství musí být mezi 1 (\'@:player.levels.skill[1]\') a 8 (\'@:player.levels.skill[8]\').',
        leadership_max: 'Maximální hodnota vůdcovství musí být mezi 1 (\'@:player.levels.skill[1]\') a 8 (\'@:player.levels.skill[8]\').',
        stamina_skill_min: 'Minimální hodnota kondice musí být mezi 0 (\'@:player.levels.skill[0]\') a 9 (\'@:player.levels.skill[9]\').',
        stamina_skill_max: 'Maximální hodnota kondice musí být mezi 0 (\'@:player.levels.skill[0]\') a 9 (\'@:player.levels.skill[9]\').',
        keeper_skill_min: 'Minimální hodnota Chytání musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        keeper_skill_max: 'Maximální hodnota Chytání musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_min: 'Minimální hodnota Tvorby hry musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_max: 'Maximální hodnota Tvorby hry musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_min: 'Minimální hodnota Zakončování musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_max: 'Maximální hodnota Zakončování musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        passing_skill_min: 'Minimální hodnota Přihrávek musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        passing_skill_max: 'Maximální hodnota Přihrávek musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        winger_skill_min: 'Minimální hodnota Křídla musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        winger_skill_max: 'Maximální hodnota Křídla musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        defender_skill_min: 'Minimální hodnota Bránění musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        defender_skill_max: 'Maximální hodnota Bránění musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_min: 'Minimální hodnota Standardky musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_max: 'Maximální hodnota Standardky musí být mezi 0 (\'@:player.levels.skill[0]\') a 25 (\'@:player.levels.skill[25]\').'
      },
      exists: {
        user_id: 'Uživatel již neexistuje.'
      },
      boolean: {
        promoted: '\'promoted\' parametr musí mít hodnotu Ano nebo Ne (true or false).',
        bookmarked: '\'bookmarked\' parametr musí mít hodnotu Ano nebo Ne (true or false).',
        ignored: '\'ignored\' parametr musí mít hodnotu Ano nebo Ne (true or false)',
        notifications_email: '\'notifications_email\' parametr musí mít hodnotu Ano nebo Ne (true or false).',
        notifications_desktop: '\'notifications_desktop\' parametr musí mít hodnotu Ano nebo Ne (true or false).'
      },
      array: {
        specialties: 'Speciality musí obsahovat pole hodnot.'
      }
    },
    unknown: {
      general: 'Došlo k neočekáváné chybě. Doporučujeme obnovit stránku.'
    },
    network: {
      general: 'Máte problémy s připojením k síti.',
      maintenance: 'Údržba systému. Zkuste to znova za pár minut.'
    },
    denied: {
      access: 'Přístup odepřen.'
    },
    expired: {
      token: 'Autentifikační token vypršel.',
      session: 'Uživatel není autentifikován.'
    },
    failed: {
      registration: 'Nebylo možné dokončit registraci.',
      user_details: 'Nebylo možné získat údaje o uživateli.',
      player_details: 'Nebylo možné získat údaje o hráči.',
      filter_fetch: 'Nebylo možné načíst nové hráče.',
      password_update: 'Heslo se neaktualizovalo.',
      chpp_connection: 'Nebylo možné začít CHPP spojení.',
      chpp_authorization: 'Nebylo možné začít CHPP autorizaci.'
    },
    invalid: {
      credentials: 'Neplatné přihlašovací údaje.',
      token: 'Autentifikační Token je neplatný.',
      request: 'Neplatná žádost.'
    },
    limit: {
      filters: 'Vytvořili jste maximální možný počet filtrů.'
    },
    missing: {
      token: 'Autentifikační token chybí.'
    },
    conflict: {
      username: 'Uživatelské jméno je již obsazeno.',
      username_password: 'Uživatelské jméno a/nebo email je již obsazeno.'
    }
  }
}
